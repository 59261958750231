// const ENV = process.env.NODE_ENV;
const ENV = "production";

const config = {
  development: {
    baseURL: `${window.location.protocol}//${window.location.hostname}:3000`,
  },
  production: {
    // baseURL: "https://apm-api.azurewebsites.net",
    baseURL: "https://apm-api.herokuapp.com", // produção
    // baseURL: "http://192.168.0.172:3000", // local Tilo
  },
};

export default config[ENV];
