// const ENV = process.env.NODE_ENV;
const ENV = "production";

const config = {
  development: {
    baseURL: `${window.location.protocol}//${window.location.hostname}:3000`,
  },
  production: {
    // baseURL: "https://apm-api.azurewebsites.net",
    baseURL: "https://apm-api.herokuapp.com",
  },
};

export default config[ENV];
